import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e88cd59"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal--wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, {
      name: $props.transitionName,
      appear: ""
    }, {
      default: _withCtx(() => [
        ($props.isOpen)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["modal", $props.classes])
            }, [
              _createElementVNode("div", {
                class: "modal--backdrop",
                onClick: $setup.close
              }),
              ($props.showContainer)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["modal--container scroll-wrapper", {'max-width' : $setup.props.maxWidth }])
                  }, [
                    _createElementVNode("div", _hoisted_1, [
                      ($props.showClose)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "modal--close",
                            onClick: $setup.close
                          }))
                        : _createCommentVNode("", true),
                      _renderSlot(_ctx.$slots, "content", {}, undefined, true)
                    ])
                  ], 2))
                : _renderSlot(_ctx.$slots, "content", { key: 1 }, undefined, true)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["name"])
  ]))
}